import { useStaticQuery, graphql } from 'gatsby'

export function useSiteConfig() {
  const { site: { siteMetadata: { description, title, keywords, environment } } } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          description
          title
          environment
          keywords
        }
      }
    }
  `)

  const baseUrl = environment === 'development'
    ? 'http://localhost:3000'
    : 'https://mcntags.com'

  const useTest = environment === 'development'
    || (typeof window != 'undefined' && window.location.search.includes('test'))

  const path = useTest ? '/test' : ''

  const citizenApi = `${baseUrl}/api/citizen${path}`

  const insuranceApi = `${baseUrl}/api/insurance${path}`

  const tagRenewalInfoApi = `${baseUrl}/api/tag-renewal-info${path}`
  
  const lienTitleRequestInfoApi = `${baseUrl}/api/lien-title-request-info${path}`
  const duplicateTitleRequestInfoApi = `${baseUrl}/api/duplicate-title-request-info${path}`
  
  const decalReplacementInfoApi = `${baseUrl}/api/decal-replacement-info${path}`

  const personalizedTagInfo = `${baseUrl}/api/personalized-tag-info${path}`
  
  const registrationRequestInfoApi = `${baseUrl}/api/registration-request-info${path}`
  
  const repoInstructionsInfoApi = `${baseUrl}/api/repo-instructions-info${path}`
  
  const changeAddressInfoApi = `${baseUrl}/api/change-address-info${path}`
  
  const disabilityPlacardInfo = `${baseUrl}/api/disability-placard-info${path}`
  
  const vehicleInfoRequestApi = `${baseUrl}/api/vehicle-info${path}`

  const boatRenewalInfoApi = `${baseUrl}/api/boat-renewal-info${path}`

  const uploadApi = `${baseUrl}/api/upload${path}`

  return {
    description,
    title,
    keywords,
    citizenApi,
    insuranceApi,
    tagRenewalInfoApi,
    uploadApi,
    lienTitleRequestInfoApi,
    duplicateTitleRequestInfoApi,
    decalReplacementInfoApi,
    registrationRequestInfoApi,
    repoInstructionsInfoApi,
    changeAddressInfoApi,
    disabilityPlacardInfo,
    personalizedTagInfo,
    vehicleInfoRequestApi,
    boatRenewalInfoApi
  }
}



import { Link } from 'gatsby'
import React from 'react'
import styles from './header.module.scss'
import PropTypes from 'prop-types'

export default function Header({ title }) {
  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <Link to='/' className={styles.logoContainer}>
          <img className={styles.logo} src="/logo.svg" alt="Muscogee (Creek) Nation Logo" />
        </Link>
        <div className={styles.title}>
          {title && <h1>{title}</h1>}
        </div>
        <div className={styles.contact}>
          <pre className={styles.contactText}>
            Need help?<br></br>   Call us:
            <span><a href="tel:19189380568">1.918.938.0568</a></span>
          </pre>
        </div>
      </div>
    </section>
  )
}

Header.propTypes = {
  title: PropTypes.string
}

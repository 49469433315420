import React, { useContext } from 'react'
import styles from './footer.module.scss'
import { Context } from '../context/provider'
import { Link } from 'gatsby'

export default function Footer() {
  const context = useContext(Context)
  const current = context?.current

  const useThanksFooter = typeof window != 'undefined'
    ? window.location.href.includes('thankyou')
    : false

  const containerStyle = styles.container
    + ` ${(!isNaN(parseInt(current)) || useThanksFooter) ? `${styles.green}` : ""}`

  const defaultFooter = <>
    <p className={styles.trouble}>
      Having trouble? We can assist you with this form over the phone.
      <span>&nbsp;<a href="tel:19189380568"><br></br>Call us: 1.918.938.0568</a></span>
    </p>
    <p className={styles.hours}>Monday - Friday 8AM - 4PM</p>
  </>

  const thanksFooter = <p>
    For more information about goverment and/or interior affairs,
    <br /><span>&nbsp;<a href="https://www.mcn-nsn.gov/">visit us https://www.mcn-nsn.gov/</a></span>
  </p>

  return (
    <section className={styles.section}>
      <div className={containerStyle}>
        {useThanksFooter ? thanksFooter : defaultFooter}
        <div className={styles.station}>
          <a href="https://station8branding.com" target="_blank" rel="noreferrer">
            Web design by Station8
          </a>
          <button><Link to="/portal">- Portal</Link></button>
          </div>
        <img src="/footer.svg" alt="" />
      </div>
    </section>
  )
}

import React from 'react'
import * as styles from './button.module.scss'
import PropTypes from 'prop-types'

export default function Button({ direction, onClick, label }) {
  const style = direction === 'right' ? styles.next : styles.back

  const imgSrc = direction === 'right' ? '/arrow-right.svg' : '/arrow-left.svg'

  return (
    <button className={styles.button + ` ${style}`} onClick={onClick}>
      {direction === 'right' && <span>{label}</span>}
      <span><img src={imgSrc} alt=""/></span>
      {direction === 'left' && <span>{label}</span>}
    </button>
  )
}

Button.propTypes = {
  direction: PropTypes.oneOf(["left", "right"]),
  onClick: PropTypes.func,
  label: PropTypes.string
}

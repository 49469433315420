import axios from 'axios'
import { useSiteConfig } from '../components/layout/getSiteConfig'

export function usePostError() {
  const { useTest } = useSiteConfig()

  return async (error) => {
    const clientError = error.stack

    if (!useTest) {
      await axios.post('https://mcntags.com/api/log-error', { clientError })
    }
    
    alert(error.message)
  }
}
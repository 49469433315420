import React from 'react'
import Button from './button'
import styles from './buttons.module.scss'
import PropTypes from 'prop-types'

export default function Buttons({
  handleBack,
  handleNext,
  backLabel,
  nextLabel,
  children
}) {

  const style = [styles.buttonContainer, `${!handleBack ? styles.noBack : ''}`]
    .filter(x => x)
    .join(' ')

  return (
    <div className={style}>
      {children}
      {handleBack && <Button {...{
        label: backLabel,
        direction: 'left',
        onClick: handleBack
      }} />}
      {handleNext && <Button {...{
        label: nextLabel,
        direction: 'right',
        onClick: handleNext
      }} />}
    </div>
  )
}

Buttons.propTypes = {
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  backLabel: PropTypes.string,
  nextLabel: PropTypes.string,
  children: PropTypes.node
}
import React, { createContext } from 'react'
import PropTypes from 'prop-types'

export const Context = createContext(null)

export const MockContextProvider = ({ current, setCurrent, children }) => {
  return <Context.Provider value={{ current, setCurrent }}>
    {children}
  </Context.Provider>
}

MockContextProvider.propTypes = {
  current: PropTypes.any,
  setCurrent: PropTypes.func,
  children: PropTypes.node
}

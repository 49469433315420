import React from 'react'
import Buttons from '../buttons/buttons'
import Footer from '../footer/footer'
import Header from '../header/header'
import styles from './errorBoundary.module.scss'
import PropTypes from 'prop-types'

class ErrorBoundary extends React.Component {
  state = { hasError: false };
  static getDerivedStateFromError() {
      return { hasError: true };
  }
  componentDidCatch(error) {
    this.handleError(error)
  }
  render() {
      if (this.state.hasError) {
          return (
            <>
              <Header title={false} />
                <div className={styles.errorFallback}>Something went wrong</div>
                <Buttons {...{
                  handleNext: () => window.location.reload(),
                  nextLabel: 'Reload'
                }} />
              <Footer />
            </>
          )
      }
      return this.props.children
  }  
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  handleError: PropTypes.func
}

export { ErrorBoundary }

import React, { useState, useEffect } from 'react'
import styles from './layout.module.scss'
import Helmet from 'react-helmet'
import { useSiteConfig } from './getSiteConfig'
import { ErrorBoundary } from '../errorBoundary/errorBoundary'
import PropTypes from 'prop-types'
import { usePostError } from '../../utilities/usePostError'

export const checkStorage = () => {
  try {
    window.sessionStorage.setItem('sessionstoragetest', 'test');
    window.sessionStorage.removeItem('sessionstoragetest');
    return true;
  } catch {
    return false;
  }
}

export default function Layout({ children }) {
  const [storageError, setStorageError] = useState(false);
  const { description, title, keywords } = useSiteConfig();
  const handleError = usePostError()

  setTimeout(() => {
    if (typeof window !== 'undefined')
      window.sessionStorage.clear();
  }, 1800000)

  useEffect(() => {
    if(!checkStorage()) {
      setStorageError(true)
    }
  }, [])

  return (
    <ErrorBoundary {...{ handleError }}>
      <div className={styles.layout}>
        <Helmet>
          <html lang='en' />
          <title>{title}</title>
          <meta name='description' content={description}/>
          <meta name='keywords' content={keywords} />
          <meta name="google-site-verification" content="sMQoOvSfRGO3y8TN8E0geoirlebY3wzXX1BVyxoFP7E" />
        </Helmet>
        {children}
        {storageError &&
        <p className={styles.storage}>This form site requires cookies to be enabled.</p>}
      </div>
    </ErrorBoundary>
  )
}

Layout.propTypes = {
  children: PropTypes.node
}